import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { ContractorsGLMeta } from "../data/seo-meta"
import CallToAction from "../components/call-to-action"

import contractorImg from "../images/cgl/contractor.svg"
import windowGazerImg from "../images/cgl/window-gazer.svg"
import hotAirBalloon from "../images/common/hot_air_ballon.svg"
import quoteIcon from "../images/common/quote.svg"

import diamondIcon from "../images/common/diamond.svg"
import touchIcon from "../images/common/touch.svg"
import checkCircleIcon from "../images/common/check_circle.svg"

import { platformLink } from "../data/external-links"

const highlightFeatureContainerClass = 'px-10 py-16 rounded-2xl drop-shadow'

const smallBusinessChecklist = [
  '60+ class codes',
  'Premiums up to $10k',
  'Pay monthly or annually using a credit card.',
  'Quote in 30 seconds & issuing in under 5 min.'
]

const midMarketChecklist = [
  '70+ class codes',
  'Premiums averaging $50k',
  'Project Specific Policies (up to 24 months)',
  'Owner’s Interest Policies'
]

const cglQuotes = [
  {
    quote: "You have the best and most thorough quote so far for the coverage my customer requires.",
    author: "Robin M.",
    location: "TN",
  },
  {
    quote: "Everything was nice and smooth, and fast! Loved it!",
    author: "Gina.",
    location: "TX",
  },
  {
    quote: "Excellent service provided, transparent insurance process, user-friendly and hassle-free online platform.",
    author: "Christina B.",
    location: "TX",
  }
]

const ContractorsGLPage = () => {
  return (
    <Layout>
      <SEO
        description={ContractorsGLMeta.description}
        path={ContractorsGLMeta.path}
        title={ContractorsGLMeta.title}
      />
      <div className="container py-20 md:py-24">
        <div className="grid md:grid-cols-2 gap-20 mb-32">
          <div className="md:pr-24">
            <h1 className="h1 mb-8">Contractors General Liability</h1>
            <p className="p1">
              Our Small Business and Mid-Market Contractors
              General Liability coverage is designed for artisans,
              general contractors and remodelers across 48 states.
              <br />
              <br />
              Generate quotes quickly, streamline your service, and enhance client satisfaction.
            </p>
            <br />
            <CallToAction label="Access Slice" to={platformLink} external />
          </div>
          <img src={contractorImg} alt="Contractor working on a house" />
        </div>

        <h2 className="h2 mb-8">Our Small Business and Mid-Market products offer:</h2>
        <div className="grid gap-10 md:grid-cols-3 mb-32">
          <div className={highlightFeatureContainerClass}>
            <img className="w-16 mb-8" src={diamondIcon} alt="Plus icon" />
            <h4 className="h4 mb-8">AM Best A++ (Superior) Rating</h4>
            <p className="p2">The highest level of financial strength and stability on E&S paper reassures clients of reliable coverage.</p>
          </div>
          <div className={highlightFeatureContainerClass}>
            <img className="w-16 mb-8" src={touchIcon} alt="Plus icon" />
            <h4 className="h4 mb-8">Coast-to-Coast Coverage</h4>
            <p className="p2">Effortlessly online and without the hassle in 48 States + DC. (excluding LA, NY.)</p>
          </div>
          <div className={highlightFeatureContainerClass}>
            <img className="w-16 mb-8" src={checkCircleIcon} alt="Plus icon" />
            <h4 className="h4 mb-8">Highlighted Features</h4>
            <ul className="list-['-'] list-inside">
              <li className="p2"> Coverage for hard to place classes</li>
              <li className="p2"> New business accepted</li>
              <li className="p2"> Completed ops form option</li>
              <li className="p2"> Per project aggregate</li>
              <li className="p2"> Unlimited instant COIs</li>
            </ul>
          </div>
        </div>

        <div className="grid md:grid-cols-2 gap-20 mb-32">
          <div>
            <h2 className="h2 mb-8">Quote & issue <b>Small Business</b> insurance with ease.</h2>
            <ul className="checklist mb-16">
              {smallBusinessChecklist.map((item, index) => (
                <li key={index} className="p1">{item}</li>
              ))}
            </ul>
            <CallToAction
              label="Download Small Business Appetite Guide"
              to="https://info.sliceinsurance.com/contractors-general-liability-e-and-s-appetite-guide-download"
              className="mb-16"
              external
            />
            <img src={windowGazerImg} className="justify-self-center" alt="Gazing out of a window" />
          </div>
          <div>
            <h2 className="h2 mb-8">Comprehensive <b>Mid-Market</b> coverage made simple.</h2>
            <ul className="checklist mb-16">
              {midMarketChecklist.map((item, index) => (
                <li key={index} className="p1">{item}</li>
              ))}
            </ul>
            <CallToAction
              label="Download Mid-Market Appetite Guide"
              to="https://info.sliceinsurance.com/contractors-general-liability-m-appetite-guide-download-mid-market"
              className="mb-16"
              external
            />
            <p className="p2 mb-8 lg:w-3/4">
              Enjoy <i>early access</i> to a new Mid-Market offering for Slice Contractors General Liability insurance.
            </p>
            <h4 className="h4">How to Submit for Mid-Market</h4>
            <p className="p2">
              Email the following customer details:
              <ul className="list-disc list-inside">
                <li>a completed ACORD form</li>
                <li>supplemental contractor application</li>
                <li>5 years of loss history</li>
                <li>company website</li>
              </ul>
              to:{' '}
              <a
                href="mailto:underwriting@sliceinsurance.com"
                rel="noreferrer nofollow"
                target="_blank"
                className="font-semibold"
              >
                underwriting@sliceinsurance.com
              </a>
            </p>
          </div>
        </div>

        <div className="grid gap-8 md:grid-cols-3 mb-40">
          {cglQuotes.map((quoteObj) => (
            <div
              className="-mx-8 px-10 py-16 bg-[#f8f8f8] md:mx-0 rounded-2xl drop-shadow flex flex-col justify-between h-full"
              key={quoteObj.author}
            >
              <div className="mb-4">
                <img className="mb-8" src={quoteIcon} alt="Quote icon" />
                <p className="text-[2.4rem] leading-[3.6rem] italic">{quoteObj.quote}</p>
              </div>
              <p className="text-[1.6rem] leading-[3.2rem]">
                  {quoteObj.author}<br />
                  {quoteObj.location}
                </p>
            </div>
          ))}
        </div>

        <div className="md:text-center mt-48">
          <img className="mx-auto" src={hotAirBalloon} alt="Hot air balloon" />
          <h2 className="h1 my-8">Quote with Slice today.</h2>
          <p className="p1">
            Create your account or log in to access Slice.
            <br />
            <br />
            <CallToAction label="Access Slice" to={platformLink} external />
          </p>
        </div>
      </div>
    </Layout>
  )
}

export default ContractorsGLPage
